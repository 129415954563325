import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faArrowsLeftRight} from "@fortawesome/free-solid-svg-icons";
import {Exam, ExamParticipant} from "../api/dto";
import { Select } from "../components/form/Select";
import {useNavigate} from "react-router-dom";

export const MoveExamParticipantModal: FC<{exams: Exam[], exam: Exam&{participants: ExamParticipant[]}}> = (props) => {
  const {moveParticipant} = useApiCall()
  const reload = useRefresh()
  const navigate = useNavigate()

  // Participant
  const [participant, setParticipant] = useState<ExamParticipant['id']>('-')
  const participantOptions = Object.fromEntries([
      ['-', 'Selecteer deelnemer'],
      ...props.exam.participants
          .sort((a,b) => b.name.localeCompare(a.name))
          .map(p => ([p.id, p.name]))
  ])


  // Exams
  const [exam, setExam] = useState<Exam['id']>(props.exam.id)
  const examOptions = Object.fromEntries(props.exams
          .filter(e => !e.archivedAt)
          .filter(e => e.questionSetId === props.exam.questionSetId)
          .sort((a,b) => b.name?.localeCompare(a.name??'') ?? -1)
          .map(e => ([e.id, e.id === props.exam.id ? `${e.name} (huidig)` : (e.name??'-')]))
  )

  const confirm = async () => {
    await moveParticipant(props.exam.id, participant, exam)
    reload()
    navigate(`/exams/${exam}`)
  }

  return <>
    <p>
      <strong>Let op!</strong> Dit verplaatst deze deelnemer, <strong>inclusief resultaten</strong> naar het gekozen examen.
    </p>
    <div className={'grid grid-cols-1 gap-3 mt-3'}>
      <Select options={participantOptions} value={participant} onChange={setParticipant} label={'Deelnemer'} />
      <Select options={examOptions} value={exam} onChange={setExam} label={'Examen'} />
    </div>
    <ModalFooter icon={faArrowsLeftRight} text={"Verplaatsen"} onSubmit={confirm} disabled={exam === props.exam.id || participant === '-'}/>
  </>
}
