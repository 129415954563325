import React, {FC, useEffect, useState} from "react";
import {Company} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {setUser} from "@sentry/react";

export const AddUserModal: FC<{}> = props => {
  const {addUser} = useApiCall()
  const refresh = useRefresh()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [errors, setErrors] = useState<string[]>([])
  const save = async () => {
    await addUser(firstName, lastName, email, username, password)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
    refresh()
  }
  return <div>
    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3 mb-3'}>
      <Input type={'text'} placeholder={'Martijn'} label={'Voornaam'} value={firstName} onChange={setFirstName}/>
      <Input type={'text'} placeholder={'Dijkgraaf'} label={'Achternaam'} value={lastName} onChange={setLastName}/>
    </div>
    <div className={'grid grid-cols-1 gap-3'}>
      <Input type={'text'} placeholder={'martijn'} label={'Inlognaam'} value={username} onChange={setUsername}/>
      <Input type={'email'} placeholder={'martijn@dijkgraaf-support.com'} label={'Email'} value={email} onChange={setEmail}/>
      <Input type={'password'} label={'Wachtwoord'} value={password} onChange={setPassword}/>
    </div>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}

    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
