import React, {FC} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faTrash, faUndo} from "@fortawesome/free-solid-svg-icons";
import {Company} from "../api/dto";

export const ArchiveCompanyModal: FC<{company: Company }> = (props) => {
  const {archiveCompany} = useApiCall()
  const reload = useRefresh()

  const confirm = async () => {
    await archiveCompany(props.company.id)
    reload()
  }
  if (props.company.isArchived) {
    return <>
      <p>
        <strong>Let op!</strong> Weet je zeker dat je het bedrijf <strong>{props.company.name}</strong> wilt Terugzetten?
      </p>
      <ModalFooter icon={faUndo} text={"Terugzetten"} onSubmit={confirm}/>
    </>
  }

  return <>
    <p>
      <strong>Let op!</strong> Als je dit bedrijf <strong>{props.company.name}</strong> archiveert worden ook alle onderliggende projecten gearchiveerd.
    </p>
    <ModalFooter icon={faTrash} text={"Verwijderen"} onSubmit={confirm}/>
  </>
}
