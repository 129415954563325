import React, {FC, useEffect, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faCopy, faSave} from "@fortawesome/free-solid-svg-icons";
import {Company, Exam, Project} from "../api/dto";
import { Select } from "../components/form/Select";
import {Input} from "../components/form/Input";
import {v4 as uuid} from "uuid"
import {useNavigate} from "react-router-dom";
import {Toggle} from "../components/form/Toggle";

export const EditExamModal: FC<{companies: Company[], projects: Project[], exams: Exam[], exam: Exam}> = (props) => {
  const {addOrUpdateExam} = useApiCall()
  const reload = useRefresh()

  // Company
  const [company, setCompany] = useState<Company['id']>(props.exam.companyId)
  const companyOptions = Object.fromEntries([
    ['-', 'Selecteer bedrijf'],
    ...props.companies
        .filter(c => !c.isArchived)
        .map(c => ([c.id, c.name]))
  ])

  // Project
  const [project, setProject] = useState<Project['id']>(props.exam.projectId)
  const projectOptions = Object.fromEntries([
    ['-', 'Selecteer project'],
    ...props.projects
        .filter(p => !p.isArchived)
        .filter(p => p.companyId === company)
        .map(p => ([p.id, p.name]))
  ])
  useEffect(() => {
    if (! Object.keys(projectOptions).includes(project)) {
      setProject('-')
    }
  }, [project, projectOptions])

  // Exam name
  const [name, setName] = useState<string>(props.exam.name ?? '')
  const examNames = Array.from(new Set(props.exams.filter(e => e.id !== props.exam.id).map(e => e.name ?? '-')))
  const isUnique = ! examNames.includes(name)

  // Exam start at
  const [startAt, setStartAt] = useState<Date|null>(props.exam.startedAt)
  const [finishedAt, setFinishedAt] = useState<Date|null>(props.exam.finishedAt)

  const confirm = async () => {
    await addOrUpdateExam(props.exam.id, {
      company_id: company,
      project_id: project,
      question_set_id: props.exam.questionSetId,
      assessor_id: props.exam.assessorId,
      started_at: startAt?.toISOString() ?? null,
      finished_at: startAt === null ? null : (finishedAt?.toISOString() ?? null),
      name: name,
    })
    reload()
  }

  return <>
    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
      <div className={'grid grid-cols-1 gap-3'}>
        <Select options={companyOptions} value={company} onChange={setCompany} label={'Bedrijf'} />
        <Select options={projectOptions} value={project} onChange={setProject} label={'Project'} />
        <Input type={'text'} label={'Examennaam'} value={name} onChange={setName} />
        {isUnique ? null : <p className={'text-red-500'}>Deze examennaam is al in gebruik.</p>}
      </div>
      <div className={'grid grid-cols-1 gap-3'}>
        <Toggle label={'Examen gestart?'} value={startAt !== null} onChange={(newValue) => !newValue ? setStartAt(null) : setStartAt(props.exam.startedAt ?? props.exam.examAt ?? new Date())} />
        {startAt !== null ? <>
          <Input type={'time'} label={'Starttijd'} value={startAt} onChange={setStartAt} />
          <Toggle label={'Examen afgerond?'} value={finishedAt !== null} onChange={(newValue) => !newValue ? setFinishedAt(null) : setFinishedAt(props.exam.finishedAt ?? props.exam.examAt ?? new Date())} />
          {finishedAt !== null ? <>
            <Input type={'time'} label={'Eindtijd'} value={finishedAt} onChange={setFinishedAt} />
          </> : <></>}
        </> : <></>}
      </div>
    </div>
    <ModalFooter icon={faSave} text={"Opslaan"} onSubmit={confirm} disabled={company === '-' || project === '-' || !isUnique}/>
  </>
}
