import React, {FC} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {User} from "../api/dto";

export const DeleteUserModal: FC<{user: User }> = (props) => {
  const {deleteUser} = useApiCall()
  const reload = useRefresh()

  const confirm = async () => {
    await deleteUser(props.user.id)
    reload()
  }

  return <>
    <p>
      <strong>Let op!</strong> Weet je zeker dat de gebruiker <strong>{props.user.username}</strong> wilt verwijderen?
    </p>
    <p>
      Dit is een <strong>permanente</strong> actie en kan niet ongedaan worden gemaakt.
    </p>
    <ModalFooter icon={faTrash} text={"Verwijderen"} onSubmit={confirm}/>
  </>
}
