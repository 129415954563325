import React, {FC, useCallback, useMemo} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronLeft,
  faChevronRight,
  faColumns, faFileImport, faList, faPeopleGroup, faQuestion,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../../util/usePersistentState";
import {Button} from "../form/Button";
import {AdminUserRole, LeadAssessorUserRole} from "../../api/dto";

const navigation: {link: string, icon: IconDefinition, text: string, allowedRoles: string[]}[] = [
  {
    link: '/',
    text: 'Alle examens',
    icon: faList,
    allowedRoles: [AdminUserRole, LeadAssessorUserRole],
  },
  {
    link: '/browse',
    text: 'Bladeren',
    icon: faColumns,
    allowedRoles: [AdminUserRole],
  },
  {
    link: '/export-import',
    text: 'Import/Export',
    icon: faFileImport,
    allowedRoles: [AdminUserRole, LeadAssessorUserRole],
  },
  {
    link: '/assessors',
    text: 'Assessors',
    icon: faPeopleGroup,
    allowedRoles: [AdminUserRole],
  },
  {
    link: '/question-sets',
    text: 'Vragenlijsten',
    icon: faQuestion,
    allowedRoles: [AdminUserRole, LeadAssessorUserRole],
  },
]
export const AuthenticatedLayout: FC<{children: React.ReactNode}> = ({children}) => {
  const {keycloak} = useKeycloak()
  const [isCollapsed, setIsCollapsed] = usePersistentState<boolean>('collapse_sidebar', false)
  const email = keycloak.tokenParsed?.['email'] as unknown as string
  const roles = useMemo(() => keycloak.tokenParsed?.realm_access?.roles ?? [], [keycloak.tokenParsed])
  const canUserAccessItem = useCallback((item: {link: string, icon: IconDefinition, text: string, allowedRoles: string[]}): boolean => {
    return item.allowedRoles.some(role => roles.includes(role))
  }, [roles])
  if (email && email.startsWith('assessor')) {
    return <div className={"p-8 text-red-700"}>
      Geen toegang
      <div className={'mt-4'}>
        <Button type={'primary'} size={'sm'} text={'Uitloggen'} onClick={() => keycloak.logout()} />
      </div>
    </div>
  }
  const linkStyle = (props: {isActive: boolean, isPending: boolean}) => `h-12 flex items-center border-l-4 ${props.isActive ? 'border-brand-900 bg-brand-50 text-black' : 'border-transparent text-gray-500  hover:bg-gray-50 hover:border-brand-900'} px-5 font-medium`
  return <div className={"flex flex-col border-red-500 h-screen bg-gray-50"}>
    <nav className={"bg-brand-900 text-white h-16 flex items-stretch justify-between"}>
      <div className={"flex justify-center items-center px-6"}>
        <h1 className={"font-bold text-xl"}>Dijkgraaf Support</h1>
      </div>
      <button className={"flex items-center hover:bg-[#0002] px-6"} onClick={() => keycloak.logout()}>
        <div className={"flex flex-col items-end justify-center mr-4"}>
          <small className={"leading-tight opacity-90"}>Ingelogd als {keycloak.idTokenParsed?.email ?? '-'}</small>
          <div className={"leading-tight"}>Uitloggen</div>
        </div>
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
      </button>
    </nav>
    <div className={"flex-1 flex items-stretch "}>
      <aside className={`bg-white ${isCollapsed ? 'w-16' : 'w-48'} border-r border-gray-200 flex flex-col justify-between pt-8`}>
        {/* Navigation */}
        <div className={'flex flex-col'}>
          {navigation.filter(item => canUserAccessItem(item)).map((item, i) => <NavLink to={item.link} className={linkStyle} key={i}>
            <span className={`w-5 text-center ${isCollapsed ? '' : 'mr-3'}`}>
              <FontAwesomeIcon icon={item.icon} />
            </span>
            {!isCollapsed && <span>{item.text}</span>}
          </NavLink> )}
        </div>
        <button className={"hover:bg-gray-50 h-12"} onClick={() => setIsCollapsed(x => !x)}>
          <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
        </button>
      </aside>
      <main className={'flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)] mx-4'}>
        {children}
      </main>
    </div>
  </div>
}
