export function setToken(token: string|null): void {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return
  }
  if (token === null) {
    window.localStorage.removeItem('session')
  } else {
    window.localStorage.setItem("session", token)
  }
}
export function getToken(): string|null {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return null
  }
  return window.localStorage.getItem("session")
}
