import React, {FC, useEffect, useState} from "react";
import {Company, User} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {setUser} from "@sentry/react";

export const ResetUserPasswordModal: FC<{user: User}> = props => {
  const {updateUserPassword} = useApiCall()
  const refresh = useRefresh()

  const [password, setPassword] = useState('')

  const [errors, setErrors] = useState<string[]>([])
  const save = async () => {
    await updateUserPassword(props.user.id, password)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
    refresh()
  }
  return <div>
    <Input type={'password'} label={'Nieuw Wachtwoord'} value={password} onChange={setPassword}/>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}

    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
