import React, {FC, useState} from "react";
import {Company, Exam, ExamParticipant, Project} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import moment from "moment/moment";

interface ExportFullScorecardModalPropsProps {
  allCompanies: Company[]
  allProjects: Project[]
  allExams: Exam[]
  allParticipants: ExamParticipant[]
  company: Company
  onlyExams: Exam['id'][]
  onlyContractors: ExamParticipant['contractor'][]|null
  onlySubContractors: ExamParticipant['subContractor'][]|null
}
export const ExportFullScoreCardModal: FC<ExportFullScorecardModalPropsProps> = props => {
  const {exportSelectionFullScorecardExcel} = useApiCall()
  const examDays = Array.from(new Set(props.onlyExams.map(examId => moment(props.allExams.find(e => props.onlyExams.includes(e.id))?.examAt).format('YYYY-MM-DD'))))

  const exportAndDownload = async () => {
    const contractorsFilter = (props.onlyContractors !== null && props.onlyContractors.length > 0) ? props.onlyContractors : null
    const subContractorsFilter = (props.onlySubContractors !== null && props.onlySubContractors.length > 0) ? props.onlySubContractors : null
    const blob = await exportSelectionFullScorecardExcel(props.onlyExams, contractorsFilter, subContractorsFilter);
    const file = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = file
    // const project = props.filteredParticipants[0].project
    // const company = props.filteredParticipants[0].company
    a.download = 'export.xlsx';//getOverallDataFileName(props.filteredParticipants, company, project)
    a.click()
    URL.revokeObjectURL(file)
  }

  return <div>
    <div className={'flex'}>
      <div className={'flex-1 border border-slate-200 rounded px-3 py-2'}>
        <table className={'w-full'}>
          <tbody>
            <tr><th className={'text-left font-medium'}>Test days</th><td className={'text-right'}>{examDays.length}</td></tr>
            <tr><th className={'text-left font-medium'}>Tests performed</th><td className={'text-right'}>{props.onlyExams.length}</td></tr>
            {props.onlyContractors !== null && <tr>
              <th className={'text-left font-medium'}>Contractors</th>
              <td className={'text-right'}>{props.onlyContractors.length}</td>
            </tr>}
          </tbody>
        </table>
      </div>
      <div className={'border border-slate-200 rounded w-24 ml-3 bg-slate-100 overflow-hidden'}>
        {props.company.logo ? <img src={props.company.logo} alt={props.company.name} className={'h-full w-full object-cover'}/> : <></>}
      </div>
    </div>
    <ModalFooter text={'Exporteren'} onSubmit={exportAndDownload}/>
  </div>
}
