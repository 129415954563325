import React, {FC} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faTrash, faUndo} from "@fortawesome/free-solid-svg-icons";
import {Project} from "../api/dto";

export const ArchiveProjectModal: FC<{project: Project }> = (props) => {
  const {archiveProject} = useApiCall()
  const reload = useRefresh()

  const confirm = async () => {
    await archiveProject(props.project.companyId, props.project.id)
    reload()
  }

  return <>
    <p>
      <strong>Let op!</strong> Weet je zeker dat je het project <strong>{props.project.name}</strong> wilt {props.project.isArchived ? 'Terugzetten' : 'Archiveren'}?
    </p>
    <ModalFooter icon={props.project.isArchived ? faUndo : faTrash} text={props.project.isArchived ? 'Terugzetten' : 'Archiveren'} onSubmit={confirm}/>
  </>
}
