import React, { FC } from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {DataTable} from "../components/data/DataTable";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";
import {faKey, faPencil, faTimes, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../api/api";
import {useModal} from "../components/layout/ModalProvider";
import {EditUserModal} from "../modals/EditUserModal";
import {AssessorUserRole, LeadAssessorUserRole, User} from "../api/dto";
import {Button, IconButton} from "../components/form/Button";
import {DeleteUserModal} from "../modals/DeleteUserModal";
import {AddUserModal} from "../modals/AddUserModal";
import {ResetUserPasswordModal} from "../modals/ResetUserPasswordModal";

export const AssessorsPage: FC<{}> = props => {
  return <ContentContainer size={'md'}>
    <AssessorsTable/>
    <UsersTable/>
  </ContentContainer>
}

export const AssessorsTable: FC<{}> = props => {
  const {assessors, loading, refresh } = useApi()
  const {updateAssessor, destroyAssessor} = useApiCall()
  return  <div className={"mb-6"}>
    <PageHeader>Assessors</PageHeader>
    <Loading loading={loading}>
      <DataTable
        keyProperty={'id'}
        data={assessors}
        columns={[
          {header: "Name", property: 'last_name', transform: (_, row) => `${row.first_name} ${row.last_name}`},
          {header: "Enabled", property: 'options', transform: (_, r) => r.isEnabled() ? 'Yes' : 'No'},
        ]}
        onClickActions={[
          {
            icon: (r) =>  r.isEnabled() ? faToggleOn : faToggleOff,
            text: (r) => r.isEnabled() ? 'Disable' : 'Enable',
            onClick: async (row) => {
              await updateAssessor(row, {options: {enabled: !row.isEnabled()}})
              await refresh.assessors(true)
            }
          },
          {
            icon: faTimes,
            text: 'Verwijderen',
            variant: 'danger',
            onClick: async (row) => {
              const sure = confirm('Weet je zeker dat je deze assessor wilt verwijderen?')
              if (sure) {
                await destroyAssessor(row.id).then(() => console.log('lksajdflkjsdflkj'))
                await refresh.assessors(true)
              }
            }
          }
        ]}
        placeholder={'Geen vragenlijsten gevonden'}
      />
    </Loading>
  </div>
}

export const UsersTable: FC<{}> = props => {
  const {users, loading, refresh } = useApi()
  const addUserModal = useModal({title: "Gebruiker toevoegen", body: <AddUserModal/>, size: 'md'})
  return <div className={"mb-6"}>
    <div className={'flex items-center justify-between'}>
      <PageHeader>Users</PageHeader>
      <div>
        <Button type={'primary'} size={'md'} text={'Gebruiker toevoegen'} onClick={() => addUserModal.open()} />
      </div>
    </div>
    <Loading loading={loading}>
      <DataTable
        keyProperty={'id'}
        data={users}
        columns={[
          {
            header: "Naam", property: 'last_name', transform: (value, row) => <div>
              <div>{row.first_name} {row.last_name}</div>
              <div className={'text-slate-600 text-xs'}>{row.email} &middot; {row.username}</div>
            </div>
          },
          {header: "Rol", property: 'role', transform: (role): string => role?.name ?? '-'},
          {
            header: "Bedrijven", property: "companies", transform: (companies) => {
              if (companies.length > 0) {
                return companies.join(', ')
              }
            }},
          {
            header: "Sets", property: "question_sets", transform: (sets) => {
              if (sets.length > 0) {
                return sets.join(', ')
              }
            }},
          {header: "Acties", property: "id", transform: (_, row) => {
            return <EditUserModalActions user={row}/>
            }}
        ]}
        placeholder={'Geen gebruikers gevonden'}
      />
    </Loading>
  </div>
}

const EditUserModalActions: FC <{user: User}> = (props) => {
  const editUser = useModal({title: "Pas gebruiker aan", body: <EditUserModal user={props.user} />, size: 'xl'})
  const editUserPassword = useModal({title: "Wachtwoord reset", body: <ResetUserPasswordModal user={props.user} />, size: 'md'})
  const delUser = useModal({title: "Gebruiker verwijderen", body: <DeleteUserModal user={props.user} />, size: 'md'})
  return <div className={'flex items-center space-x-3 justify-end'}>
    <IconButton type={'secondary'} size={'sm'} icon={faKey} onClick={() => editUserPassword.open()}/>
    <IconButton type={'secondary'} size={'sm'} icon={faPencil} onClick={() => editUser.open()}/>
    <IconButton type={'danger'} size={'sm'} icon={faTimes} onClick={() => delUser.open()}/>
  </div>
}
