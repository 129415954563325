import React, {FC, useMemo} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {UnauthenticatedPlaceholder, UnauthorizedPlaceholder} from "../components/layout/UnauthenticatedPlaceholder";
import {AuthenticatedLayout} from "../components/layout/AuthenticatedLayout";
import {AdminUserRole, LeadAssessorUserRole} from "../api/dto";

export const AuthMiddleware: FC<{children: React.ReactNode}> = ({children}) => {
  const {keycloak} = useKeycloak()
  const roles = useMemo(() => keycloak.tokenParsed?.realm_access?.roles ?? [], [keycloak.tokenParsed])
  if (! keycloak.authenticated) {
    return <UnauthenticatedPlaceholder />
  }
  if (! roles.includes(AdminUserRole) && ! roles.includes(LeadAssessorUserRole)) {
    return <UnauthorizedPlaceholder />
  }
  return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}
