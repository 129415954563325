import {FC, useState} from "react";
import {AdminUserRole, AssessorUserRole, LeadAssessorUserRole, User} from "../api/dto";
import {useApi} from "../api/APIContext";
import {Autocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";

const rolesWithPartialAccess: string[] = [
  AssessorUserRole, LeadAssessorUserRole,
]
const allowedRoles: string[] = [
  AssessorUserRole, LeadAssessorUserRole, AdminUserRole,
]
export const EditUserModal: FC<{user: User}> = (props) => {
  const {companies} = useApi()
  const {roles} = useApi()
  const refresh = useRefresh()
  const {close} = useModalControls()
  const {addUserRole, removeUserRole, updateUserAttributes} = useApiCall()
  const [userCompanies, setUserCompanies] = useState<string[]>(props.user.companies ?? [])
  const [userRole, setUserRole] = useState<string>(props.user.role?.name ?? '-')
  const [userSets, setUserSets] = useState<string[]>(props.user.question_sets ?? [])

  const questionSets = [['Fct', 'Flange competentie test'],
    ['Rct', 'Rigger competentie test'],
    ['Fir', 'Field inspection report']]

  const roleOptions = [['-', 'geen'], ...roles
    .filter(item => allowedRoles.includes(item.name))
    .map(item => [item.name, item.name])]

  const companyOptions = [['-', 'Geef toegang tot...'], ...companies
    .filter(item => !userCompanies.includes(item.name))
    .map(item => [item.name, item.name])]

  const questionSetOptions = [
    ['-', 'Geef toegang tot...'], ...questionSets]
    .filter(item => !userSets.includes(item[0]))
    .map(([key, value]) => [key, value]);

  const saveChanges = async () => {
    const currentRoles = props.user.roles
    const newRole = roles.find(role => role.name === userRole)
    if (!newRole) {
      return
    }
    // Remove roles that should not be on user
    if (currentRoles.length !== 1 || currentRoles[0].id !== newRole.id) {
      const removeRoleRequests: Promise<void>[] = currentRoles
        .filter(role => role.id !== newRole.id)
        .map(role => removeUserRole(props.user.id, role))
      await Promise.all(removeRoleRequests)
    }
    // Add role if not already on user
    if (! currentRoles.some(role => role.id === newRole.id)) {
      await addUserRole(props.user.id, newRole)
    }
    // Replace companies / question sets if relevant.
    const userCompaniesHaveChanged = userCompanies.sort().join(',') !== props.user.companies.sort().join(',')
    const userQuestionSetsHaveChanged = userSets.sort().join(',') !== props.user.question_sets.sort().join(',')
    if ((userCompaniesHaveChanged || userQuestionSetsHaveChanged) && rolesWithPartialAccess.includes(newRole.name)) {
      await updateUserAttributes(props.user.id, userCompanies, userSets)
    }
    refresh()
  }

  return <div>
    <table className={'w-full'}>
      <tbody>
        <tr>
          <td className={'text-sm'}>Naam</td>
          <td className={'w-full pl-4 text-sm font-medium'}>{ props.user.first_name } { props.user.last_name}</td>
        </tr>
        <tr>
          <td className={'text-sm'}>Email</td>
          <td className={'w-full pl-4 text-sm font-medium'}>{ props.user.email }</td>
        </tr>
        <tr>
          <td className={'text-sm'}>Inlognaam</td>
          <td className={'w-full pl-4 text-sm font-medium'}>{ props.user.username }</td>
        </tr>
      </tbody>
    </table>
      <hr className={'border-slate-100 -mx-4 my-3'} />

    {/*<div className={'grid grid-cols-1 gap-4 lg:grid-cols-2'}>*/}
    {/* */}

    {/*</div>*/}

    <div className={'grid grid-cols-1 gap-4 lg:grid-cols-2'}>
      <div className={""}>
        <div className={"mb-2"}>
          <Select label={'Rol'} options={Object.fromEntries(roleOptions)} value={userRole} onChange={(nr) => {
            if (nr === '-') {
              // This is just a placeholder. "geen" is not a valid role.
              return
            }
            setUserRole(nr);
          }}/>
        </div>
        <div className={'grid grid-cols-1 gap-3 mb-4 mt-1 mx-1'}>
          <div>
            <h2 className={'text-sm font-medium'}>Rol: admin</h2>
            <p className={'text-sm text-slate-700 leading-tight'}>
              Inloggen in web-app, kan overal bij en alles aanpassen.
            </p>
          </div>
          <div>
            <h2 className={'text-sm font-medium'}>Rol: lead-assessor</h2>
            <p className={'text-sm text-slate-700 leading-tight'}>
              Inloggen in web-app, mag enkel bij examens en import/export. Beperkt op enkel(e) bedrijf(en).
            </p>
          </div>
          <div>
            <h2 className={'text-sm font-medium'}>Rol: lead-assessor</h2>
            <p className={'text-sm text-slate-700 leading-tight'}>
              Mag niet in web-app. Kan in de app examens afnemen voor enkel(e) bedrijf(en).
            </p>
          </div>

        </div>
      </div>
      {rolesWithPartialAccess.includes(userRole) && <div className={""}>
        <div className={"mb-2"}>
          <Autocomplete label={'Beperk toegang tot bedrijven'} disabled={Object.values(companyOptions).length < 2}
                        options={Object.fromEntries(companyOptions)} value={'-'} onChange={(option) => {
            if (option !== '-') {
              setUserCompanies([...userCompanies, option])
            }
          }}/>
        </div>
        {userCompanies.map((uc, i) => {
          return <div key={i}
                      className={'mt-2 h-10 pl-3 flex items-center border border-slate-200 rounded bg-brand-50'}>
            <div className={'flex-1 font-medium text-sm'}>{uc}</div>
            <button className={'h-6 w-6 m-1 hover:bg-red-100 rounded-sm flex items-center justify-center'}
                    onClick={() => {
                      setUserCompanies(old => old.filter(item => item !== uc))
                    }}><FontAwesomeIcon icon={faTimes} className={'text-red-600'}/></button>
          </div>
        })}

        <hr className={'my-6'}/>

        <div className={""}>
          <div className={"mb-2"}>
            <Select label={'Beperk toegang tot deze testen'} options={Object.fromEntries(questionSetOptions)} value={'-'}
                    onChange={(option) => {
                      if (option !== '-') {
                        setUserSets([...userSets, option])
                      }
                    }}/>
          </div>
        </div>
        <div className={'mt-4'}>
          {userSets.map((set, i) => {
            const set_item = questionSets.find((value) => value[0] === set)
            return <div key={i}
                        className={'mt-2 h-10 pl-3 flex items-center border border-slate-200 rounded bg-brand-50'}>
              <div className={'flex-1 font-medium text-sm'}>{set_item![1] ?? set}</div>
              <button className={'h-6 w-6 m-1 hover:bg-red-100 rounded-sm flex items-center justify-center'}
                      onClick={() => {
                        setUserSets(old => old.filter(item => item !== set))
                      }}><FontAwesomeIcon icon={faTimes} className={'text-red-600'}/></button>
            </div>
          })}
        </div>

      </div>}
    </div>
    <ModalFooter text={'Opslaan'} onSubmit={saveChanges}/>
  </div>
}
